import React, {useEffect} from 'react';
import {Routes, Route, useLocation, useNavigate} from 'react-router-dom'
import './App.css';
import GenericNotFound from './GenericNotFound';
import HomePage from './pages/HomePage';
import Article from './pages/Article'
import TopBar from './components/generalComponents/TopBar';
import BottomBar from './components/generalComponents/BottomBar';
import AuthorsArticle from './pages/AuthorsArticle';
import Login from './pages/Login';
import ContactUs from './pages/ContactUs';
import Eula from './pages/Eula';
import Privacy from './pages/Privacy';
import Subscription from './pages/Subscription';
import NewsAnalysis from './pages/NewsAnalysis';
import About from './pages/About';
import Blog from './pages/Blog';
import EmailVerification from './pages/EmailVerification';
import EmailVerified from './pages/EmailVerified';
import axios from 'axios';
import ChangePassword from './pages/ChangePassword';
import ForgotPass from './pages/ForgotPass';
import PasswordReset from './pages/PasswordReset';
import SignUp from './pages/Signup';
import MessageSuccess from './components/pageComponents/Login/MessageSuccess';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import Banner from './pages/Banner';
import ReactGA from 'react-ga4';
import { initializeApp } from 'firebase/app';
import { getMessaging, getToken } from "firebase/messaging";
import {
  GoogleReCaptchaProvider
} from 'react-google-recaptcha-v3';
import Subscribe from './pages/Subscribe';

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyD1-De5FMI6TKj4KNIjKWTANE9FaGWqvUQ",
  authDomain: "the-morning-view.firebaseapp.com",
  projectId: "the-morning-view",
  storageBucket: "the-morning-view.appspot.com",
  messagingSenderId: "765548583577",
  appId: "1:765548583577:web:6157e39e30770aa582b608",
  // measurementId: "G-K9V4LZ8TRN",
  measurementId: "G-BJSJY24073"
};

var firebaseInitialized = false;

// getOrRegisterServiceWorker function is used to try and get the service worker if it exists, otherwise it will register a new one.
export const getOrRegisterServiceWorker = () => {
  if (
    "serviceWorker" in navigator &&
    typeof window.navigator.serviceWorker !== "undefined"
  ) {
    return window.navigator.serviceWorker
      .getRegistration("/firebase-push-notification-scope")
      .then((serviceWorker) => {
        if (serviceWorker) return serviceWorker;
        return window.navigator.serviceWorker.register(
          "/firebase-messaging-sw.js?v=" + (+(new Date().getTime())),
          {
            scope: "/firebase-push-notification-scope",
          }
        );
      });
  }
  throw new Error("The browser doesn`t support service worker.");
};
 
// getFirebaseToken function generates the FCM token 
export const getFirebaseToken = async () => {
  try {
    const messaging = await getMessaging();
    if (messaging) {
      return getOrRegisterServiceWorker().then((serviceWorkerRegistration) => {
        return Promise.resolve(
          getToken(messaging, {
            vapidKey: "BCtsnH8FYgmbv2hWfzM-sg6wbwnsZYZdn-CCqLukQBNNJJwsBBAOYW5dVEuc4w7QXxjkf8Tqek6WMCVF6psAN1Q",
            serviceWorkerRegistration,
          })
        );
      });
    }
  } catch (error) {}
};

const TRACKING_ID = "G-BJSJY24073_test"; // YOUR_OWN_TRACKING_ID

ReactGA.initialize([{
  trackingId: TRACKING_ID
}]);

const updateWebFcmToken = (currentToken: any) => {
  axios.post(BaseUrl + "/update-fcm-token-web", {
    fcm_token: currentToken
  }).then((_) => {}).catch((_)=> {});
};

export const BaseUrl = "https://www.morningview.gr/backend/api";

function App() {

  const search = useLocation();
  const path = search.pathname;

  let nav = useNavigate();

  const location = useLocation();

  useEffect(() => {
    if (!firebaseInitialized) {
      try {
        firebaseInitialized = true;
        const firebaseApp = initializeApp(firebaseConfig);
        // Get registration token. Initially this makes a network call, once retrieved
        // subsequent calls to getToken will return from cache.
        Notification.requestPermission().then((permission) => {
          if (permission === 'granted') {
            getFirebaseToken().then((currentToken) => {
              if (currentToken) {
                updateWebFcmToken(currentToken);
              }
            });
          }
        });
      } catch (error) {}
    }
    // Send pageview with a custom path
    ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search });
  }, [location]);

  if (path == "/login") {
        return(
          <div>
            <Routes>
              <Route path="/login" element={<Login />} />
            </Routes>
          </div>
        )
  } else if (path === "/verify") {
    return(
      <div>
        <Routes>
          <Route path="/verify" element={ <EmailVerification/>} />
        </Routes>
      </div>  
    )
  } else if (path === "/verified") {
    return(
      <Routes>
        <Route path="/verified" element={ <EmailVerified/> }/>
      </Routes>
    )
  } else if (path === "/forgot-password") {
      return(
        <Routes>
          <Route path="/forgot-password" element={ <ForgotPass/> }/>
        </Routes>
      )
  } else if (path === "/reset-password") {
      return(
        <Routes>
          <Route path="/reset-password" element={ <PasswordReset/> }/>
        </Routes>
      )
  } else if (path === "/signup") {
    return(
      <Routes>
        <Route path="/signup" element={ <SignUp/> }/>
      </Routes>
    )
  } else if (path === "/message-success") {
    return(
      <Routes>
        <Route path="/message-success" element={ <MessageSuccess/> }/>
      </Routes>
    )
  } else {
    return (
      <GoogleReCaptchaProvider reCaptchaKey="6Le_2f4nAAAAAKZGwqcUw8c_Qaj3oVa5a_ASi_YC">
        <HelmetProvider>
              <React.Fragment>
                <Helmet>
                      <script type="text/javascript">{`!function(f,b,e,v,n,t,s){if(f.fbq)return;n=f.fbq=function(){n.callMethod?
                      n.callMethod.apply(n,arguments):n.queue.push(arguments)};if(!f._fbq)f._fbq=n;
                      n.push=n;n.loaded=!0;n.version='2.0';n.queue=[];t=b.createElement(e);t.async=!0;
                      t.src=v;s=b.getElementsByTagName(e)[0];s.parentNode.insertBefore(t,s)}(window,
                      document,'script','https://connect.facebook.net/en_US/fbevents.js');fbq('init', '1049523379673682');fbq('track', 'PageView');`}</script>
                      <noscript>{`<img height="1" width="1" style="display:none" src="https://www.facebook.com/tr?id=1049523379673682&ev=PageView&noscript=1" />`}</noscript>
                      <script src="https://bs.serving-sys.com/Serving/adServer.bs?c=28&cn=display&pli=1080269343&gdpr=${GDPR}&gdpr_consent=${GDPR_CONSENT_68}&us_privacy=${US_PRIVACY}&w=300&h=250&ord=[timestamp]&z=10000"></script>
                </Helmet>
              </React.Fragment>
              <TopBar />
              <Routes>
                <Route path='/news-analysis' element={<NewsAnalysis />} />
                <Route path='/' element={<HomePage />} />
                <Route path='/article/:slug' element={<Article />} />
                <Route path='/authors-article/:slug' element={<AuthorsArticle />} />
                <Route path='/login' element={<Login />}/>
                <Route path='/contact-us' element ={<ContactUs />} />
                <Route path='/eula' element={<Eula/>}/>
                <Route path='/app-privacy-policy' element={<Privacy/>}/>
                <Route path='/subscription' element={<Subscription/>}/>
                <Route path='/about' element ={<About />} />
                <Route path='/categories/apeleutheros' element ={<HomePage />} />
                <Route path='/categories/sketch' element ={<HomePage />} />
                <Route path='/categories/apeleutheros' element ={<HomePage />} />
                <Route path='/categories/news-and-analysis' element ={<HomePage />} />
                <Route path='/categories/gamechanger' element ={<HomePage />} />
                <Route path='/categories/marketmaker' element ={<HomePage />} />
                <Route path='/change-password' element = { <ChangePassword/>} />
                <Route path='/subscribe' element = { <Subscribe/>} />
                <Route path='*' element={ <GenericNotFound/> }/>
              </Routes>  
              <BottomBar />
        </HelmetProvider>
      </GoogleReCaptchaProvider>
    );
  }
}

export default App;
